
export function track(event, session_id) {
    fetch("/api/event",
    {
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({event, session_id})
    })
};

track('gallery');