import { useState, useEffect, useCallback } from "react";
import styled from "@emotion/styled";
import exportIcon from "./icons/export.svg";
import facebookIcon from "./icons/facebook.svg";
import linkedinIcon from "./icons/linkedin.svg";
import whatsappIcon from "./icons/whatsapp.svg";
import xIcon from "./icons/x.svg";
import banner from "./icons/banner.png";
import mobiusLogo from "./icons/mobius-logo.png";
import { GrLinkedinOption, GrYoutube, GrMailOption } from "react-icons/gr";
import { getImages } from "./api/images";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { Pagination } from "@mantine/core";
import { track } from './util'
import "./App.css";

function App() {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);

  const fetchImages = useCallback(async () => {
    const arr = await getImages();
    setImages(arr);

    setTotalPages(Math.ceil(arr.length / 12));

    const startIndex = (page - 1) * 12;
    const endIndex = startIndex + 12;
    setImagesToDisplay(arr.slice(startIndex, endIndex));
  }, [page]);

  const handlePageChange = useCallback(() => {
    const startIndex = (page - 1) * 12;
    const endIndex = startIndex + 12;
    setImagesToDisplay(images.slice(startIndex, endIndex));
    track('page');
  }, [page, images]);

  useEffect(() => {
    handlePageChange();
  }, [page, handlePageChange]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  return (
    <Container>
      <HeaderContainer>
        <a href="http://www.mobiusframe.com">
          <MobiusLogo src={mobiusLogo} alt="mobius frame logo" />
        </a>
        <HeaderLinksContainer>
          <HeaderLink href="http://www.mobiusframe.com#what">
            What we do
          </HeaderLink>
          <HeaderLink href="http://www.mobiusframe.com#technology">
            Technology
          </HeaderLink>
          <HeaderLink href="http://www.mobiusframe.com#solutions">
            Solution
          </HeaderLink>
        </HeaderLinksContainer>
        <SocialsContainer>
          <SocialLink
            href="http://www.linkedin.com/company/90419753"
            target="_blank"
            rel="noreferrer"
          >
            <CustomLinkedinIcon />
          </SocialLink>
          <SocialLink
            href="http://www.youtube.com/@mobius-frame"
            target="_blank"
            rel="noreferrer"
          >
            <CustomYoutubeIcon />
          </SocialLink>
          <SocialLink
            href="mailto:hello@mobiusframe.com"
            target="_blank"
            rel="noreferrer"
          >
            <CustomMailIcon />
          </SocialLink>
          <ContactLink href="http://www.mobiusframe.com/contact">
            Contact
          </ContactLink>
        </SocialsContainer>
      </HeaderContainer>
      <BannerContainer>
        <BannerComponent src={banner} alt="banner" />
      </BannerContainer>
      <MainContainer>
        {imagesToDisplay &&
          imagesToDisplay.map((image) => {
            return (
              <ItemContainer key={image.session_id}>
                <ImageComponent src={`https://fake.mobiusframe.com/uploads/app_blob/${image.session_id}.jpg`} alt="" />
                <IconsContainer>
                  <a href={image.url + '?download=true'}>
                    <IconComponent
                      src={exportIcon}
                      alt="export icon"
                      onClick={() => track('download', image.session_id)}
                    />
                  </a>
                  <LinkedinShareButton
                    url={`https://fake.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent src={linkedinIcon} alt="linkedin icon" onClick={() => track('linkedin', image.session_id)}/>
                  </LinkedinShareButton>
                  <WhatsappShareButton
                    url={`https://fake.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent src={whatsappIcon} alt="whatsapp icon" onClick={() => track('whatsapp', image.session_id)}/>
                  </WhatsappShareButton>
                  <TwitterShareButton
                    url={`https://fake.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent src={xIcon} alt="x icon" 
                      onClick={() => track('twitter', image.session_id)}
                    />
                  </TwitterShareButton>
                  <FacebookShareButton
                    url={`https://fake.mobiusframe.com/share/${image.session_id}`}
                  >
                    <IconComponent src={facebookIcon} alt="facebook icon"
                      onClick={() => track('facebook', image.session_id)}
                     />
                  </FacebookShareButton>
                </IconsContainer>
              </ItemContainer>
            );
          })}
      </MainContainer>
      <PaginationContainer>
        <Pagination
          value={page}
          onChange={setPage}
          total={totalPages}
          color="#D6502A"
        />
      </PaginationContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  box-sizing: border-box;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  grid-template-columns: repeat(4, 1fr);
  padding: 50px;
  display: grid;
  gap: 30px;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 30px;
    gap: 30px;
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 10px;
    gap: 10px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const IconsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;

  @media (max-width: 850px) {
    margin-bottom: 30px;
  }
`;

const IconComponent = styled.img`
  width: 25px;
  height: 25px;
  cursor: pointer;

  @media (max-width: 850px) {
    width: 19px;
    height: 19px;
  }
`;

const ImageComponent = styled.img`
  width: 250px;
  height: 325px;

  @media (max-width: 600px) {
    width: 170px;
    height: 215px;
  }
`;

const BannerComponent = styled.img`
  max-width: 509px;
  width: 100%;
`;

const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const MobiusLogo = styled.img`
  max-width: 170px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1vw 4vw;

  @media (max-width: 850px) {
    justify-content: space-between;
    margin-top: 30px;
  }
`;

const HeaderLinksContainer = styled.div`
  display: block;

  @media (max-width: 850px) {
    display: none;
  }
`;

const HeaderLink = styled.a`
  margin: 0 1vw;
  font-size: 19px;
  font-family: "Poppins", sans-serif;
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #0000ff;
  }
`;

const ContactLink = styled.a`
  box-sizing: border-box;
  padding: 1em 2em;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  color: #ffffff;
  background-color: #0000ff;

  &:hover {
    color: #0000ff;
    background-color: #ffffff;
    border: 1px solid #0000ff;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

const SocialsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.7vw;
`;

const SocialLink = styled.a`
  color: #000000;
`;

const CustomLinkedinIcon = styled(GrLinkedinOption)`
  width: 20px;
  height: 20px;
`;

const CustomYoutubeIcon = styled(GrYoutube)`
  width: 20px;
  height: 20px;
`;

const CustomMailIcon = styled(GrMailOption)`
  width: 20px;
  height: 20px;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
`;

export default App;
